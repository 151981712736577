import * as React from "react";
import {IObservable, IObservableArray} from "mobx";

export const enum conditionType {
    ADD_TOPLINK,
    ADD_HEADING,
    ADD_SUBLINK,
    EDITING,
}

export const enum currentViewType {
    heading,
    traditional,
    list,
    icons,
    external,
    existing,
    initial,
    new,
    calendar,
    document,
    folder,
    quicklink,
    quicklinkInternal,
    sharedfile,
    shortcut,
    shortcutExternal,
    shortcutInternal,
    loadingInternal,
}

declare interface modalObjType {
    type: string,
    condition: conditionType,
    nodeInfo: Navigation,
    navigation: Navigation[] & IObservableArray,
    parent_id?: string,
    terrace_id?: number,
    initialView?: currentViewType,
    navigationUpdater: (n: Navigation) => void,
    navigationCreator: (n: Navigation) => Navigation,
}

declare interface ViewProps {
    node: Navigation,
    setNode: React.SetStateAction<any>,
    handleFlowChange: (type: currentViewType) => void,
    condition: conditionType,
    setNewOrganizationType: (type:  OrganizationType) => void,
    newOrganizationType: OrganizationType,
    initialView?: currentViewType,
    blockObj: AnyBlockObj | null,
    setBlockObj: React.SetStateAction<any>,
}
